<template>
  <BModal
    :width="1253"
    :active="show"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    :can-cancel="['outside', 'escape']"
    @close="$emit('close')"
  >
    <ContentModal @close="$emit('close')" :show="show" />
  </BModal>
</template>

<script>
import ContentModal from '@/components/Sections/Lives/ContentModal';
import { BModal } from 'buefy/dist/esm/modal';
export default {
  name: 'Lives',
  components: {
    BModal,
    ContentModal,
  },
  computed: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.modal {
  z-index: 60 !important;
}
.modal-content,
.modal-card {
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 30px) !important;
  }
  @media screen and (max-width: 553px) {
    max-height: calc(100vh - 50px) !important;
    margin-top: 50px !important;
  }
}
</style>
