<template>
  <div>
    <div class="lives-games-league">
      <!-- FIFA CUP -->
      <div class="lives-games-league__containerleague" v-if="gamesFIFACup.length > 0">
        <div class="lives-games-league__containerleague__titleleague">{{ nameFIFACup }}</div>
        <div class="lives-games-league__containerleague__containergames">
          <div class="pc-games-flag">
            <template v-for="(game, i) in gamesFIFACup">
              <div class="live-gameitem" :key="i + 'LPC'">
                <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                <div class="live-gameitem__data">
                  <div class="live-gameitem__data__logo-home">
                    <img :src="game.LogoHomeTeam" />
                  </div>
                  <div class="live-gameitem__data__vs">VS</div>
                  <div class="live-gameitem__data__logo-away">
                    <img :src="game.LogoAwayTeam" />
                  </div>
                </div>
                <div class="live-gameitem__container-button">
                  <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                </div>
              </div>
            </template>
          </div>
          <div class="movil-games-flag">
            <BCarousel :interval="0" :autoplay="false" :indicator-inside="false" :pause-info="true">
              <template v-for="(game, i) in gamesFIFACup">
                <BCarouselItem :key="i + 'LMO'">
                  <div class="live-gameitem">
                    <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                    <div class="live-gameitem__data">
                      <div class="live-gameitem__data__logo-home">
                        <img :src="game.LogoHomeTeam" />
                      </div>
                      <div class="live-gameitem__data__vs">VS</div>
                      <div class="live-gameitem__data__logo-away">
                        <img :src="game.LogoAwayTeam" />
                      </div>
                    </div>
                    <div class="live-gameitem__container-button">
                      <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                    </div>
                  </div>
                </BCarouselItem>
              </template>
            </BCarousel>
          </div>
        </div>
      </div>
      <!-- LIGA MX -->
      <div class="lives-games-league__containerleague" v-if="gamesLigaMX.length > 0">
        <div class="lives-games-league__containerleague__titleleague">{{ nameLigaMX }}</div>
        <div class="lives-games-league__containerleague__containergames">
          <div class="pc-games-flag">
            <template v-for="(game, i) in gamesLigaMX">
              <div class="live-gameitem" :key="i + 'LPC'">
                <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                <div class="live-gameitem__data">
                  <div class="live-gameitem__data__logo-home">
                    <img :src="game.LogoHomeTeam" />
                  </div>
                  <div class="live-gameitem__data__vs">VS</div>
                  <div class="live-gameitem__data__logo-away">
                    <img :src="game.LogoAwayTeam" />
                  </div>
                </div>
                <div class="live-gameitem__container-button">
                  <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                </div>
              </div>
            </template>
          </div>
          <div class="movil-games-flag">
            <BCarousel :interval="0" :autoplay="false" :indicator-inside="false" :pause-info="true">
              <template v-for="(game, i) in gamesLigaMX">
                <BCarouselItem :key="i + 'LMO'">
                  <div class="live-gameitem">
                    <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                    <div class="live-gameitem__data">
                      <div class="live-gameitem__data__logo-home">
                        <img :src="game.LogoHomeTeam" />
                      </div>
                      <div class="live-gameitem__data__vs">VS</div>
                      <div class="live-gameitem__data__logo-away">
                        <img :src="game.LogoAwayTeam" />
                      </div>
                    </div>
                    <div class="live-gameitem__container-button">
                      <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                    </div>
                  </div>
                </BCarouselItem>
              </template>
            </BCarousel>
          </div>
        </div>
      </div>
      <!-- Leagues CUP -->
      <div class="lives-games-league__containerleague" v-if="gamesLeaguesCup.length > 0">
        <div class="lives-games-league__containerleague__titleleague">{{ nameLeaguesCup }}</div>
        <div class="lives-games-league__containerleague__containergames">
          <div class="pc-games-flag">
            <template v-for="(game, i) in gamesLeaguesCup">
              <div class="live-gameitem" :key="i + 'LPC'">
                <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                <div class="live-gameitem__data">
                  <div class="live-gameitem__data__logo-home">
                    <img :src="game.LogoHomeTeam" />
                  </div>
                  <div class="live-gameitem__data__vs">VS</div>
                  <div class="live-gameitem__data__logo-away">
                    <img :src="game.LogoAwayTeam" />
                  </div>
                </div>
                <div class="live-gameitem__container-button">
                  <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                </div>
              </div>
            </template>
          </div>
          <div class="movil-games-flag">
            <BCarousel :interval="0" :autoplay="false" :indicator-inside="false" :pause-info="true">
              <template v-for="(game, i) in gamesLeaguesCup">
                <BCarouselItem :key="i + 'LMO'">
                  <div class="live-gameitem">
                    <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                    <div class="live-gameitem__data">
                      <div class="live-gameitem__data__logo-home">
                        <img :src="game.LogoHomeTeam" />
                      </div>
                      <div class="live-gameitem__data__vs">VS</div>
                      <div class="live-gameitem__data__logo-away">
                        <img :src="game.LogoAwayTeam" />
                      </div>
                    </div>
                    <div class="live-gameitem__container-button">
                      <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                    </div>
                  </div>
                </BCarouselItem>
              </template>
            </BCarousel>
          </div>
        </div>
      </div>
      <!-- LIGA EXPANSIÓN -->
      <div class="lives-games-league__containerleague" v-if="gamesExpansion.length > 0">
        <div class="lives-games-league__containerleague__titleleague">{{ nameLigaEMX }}</div>
        <div class="lives-games-league__containerleague__containergames">
          <div class="pc-games-flag">
            <template v-for="(game, i) in gamesExpansion">
              <div class="live-gameitem" :key="i + 'LEPC'">
                <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                <div class="live-gameitem__data">
                  <div class="live-gameitem__data__logo-home">
                    <img :src="game.LogoHomeTeam" />
                  </div>
                  <div class="live-gameitem__data__vs">VS</div>
                  <div class="live-gameitem__data__logo-away">
                    <img :src="game.LogoAwayTeam" />
                  </div>
                </div>
                <div class="live-gameitem__container-button">
                  <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                </div>
              </div>
            </template>
          </div>
          <div class="movil-games-flag">
            <BCarousel :interval="0" :autoplay="false" :indicator-inside="false" :pause-info="true">
              <template v-for="(game, i) in gamesExpansion">
                <BCarouselItem :key="i + 'LEMO'">
                  <div class="live-gameitem">
                    <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                    <div class="live-gameitem__data">
                      <div class="live-gameitem__data__logo-home">
                        <img :src="game.LogoHomeTeam" />
                      </div>
                      <div class="live-gameitem__data__vs">VS</div>
                      <div class="live-gameitem__data__logo-away">
                        <img :src="game.LogoAwayTeam" />
                      </div>
                    </div>
                    <div class="live-gameitem__container-button">
                      <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                    </div>
                  </div>
                </BCarouselItem>
              </template>
            </BCarousel>
          </div>
        </div>
      </div>
      <!-- LIGA FEMENIL MX -->
      <div class="lives-games-league__containerleague" v-if="gamesFemenilMX.length > 0">
        <div class="lives-games-league__containerleague__titleleague">{{ nameLigaFMX }}</div>
        <div class="lives-games-league__containerleague__containergames">
          <div class="pc-games-flag">
            <template v-for="(game, i) in gamesFemenilMX">
              <div class="live-gameitem" :key="i + 'LFPC'">
                <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                <div class="live-gameitem__data">
                  <div class="live-gameitem__data__logo-home">
                    <img :src="game.LogoHomeTeam" />
                  </div>
                  <div class="live-gameitem__data__vs">VS</div>
                  <div class="live-gameitem__data__logo-away">
                    <img :src="game.LogoAwayTeam" />
                  </div>
                </div>
                <div class="live-gameitem__container-button">
                  <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                </div>
              </div>
            </template>
          </div>
          <div class="movil-games-flag">
            <BCarousel :interval="0" :autoplay="false" :indicator-inside="false" :pause-info="true">
              <template v-for="(game, i) in gamesFemenilMX">
                <BCarouselItem :key="i + 'LEMO'">
                  <div class="live-gameitem">
                    <div class="live-gameitem__date">{{ game.Date }} HRS</div>
                    <div class="live-gameitem__data">
                      <div class="live-gameitem__data__logo-home">
                        <img :src="game.LogoHomeTeam" />
                      </div>
                      <div class="live-gameitem__data__vs">VS</div>
                      <div class="live-gameitem__data__logo-away">
                        <img :src="game.LogoAwayTeam" />
                      </div>
                    </div>
                    <div class="live-gameitem__container-button">
                      <div class="button-livedata" @click="gotoGame(game.GameID, game.seasonID)">Ver datos live</div>
                    </div>
                  </div>
                </BCarouselItem>
              </template>
            </BCarousel>
          </div>
        </div>
      </div>
      <div class="fotter-space"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  components: { BCarousel, BCarouselItem },
  data() {
    return {
      url: 'https://qobetc4ze3.execute-api.us-east-2.amazonaws.com/prod/games/live',
      gamesLigaMX: [],
      gamesFemenilMX: [],
      gamesExpansion: [],
      gamesFIFACup: [],
      gamesLeaguesCup: [],
      nameLigaMX: '',
      nameLigaFMX: '',
      nameLigaEMX: '',
      nameFIFACup: '',
      nameLeaguesCup: '',
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount']),
  },
  async created() {
    await this.getLivesData();
  },
  methods: {
    ...mapActions('routes', ['goToGame']),
    ...mapMutations('nextGeneral', ['toggleShowLiveData']),

    gotoGame(game, season) {
      if (this.getPremiumAccount) this.toggleShowLiveData();
      this.goToGame({
        name: 'matchAnalysis',
        gameId: game,
        currentSeasonId: season,
        hideHeader: 1,
        page: 'calendar',
        isCup: false,
        backRoute: this.$route.name,
      });
      this.$emit('game-select');
    },

    async getLivesData() {
      try {
        const { data: games } = await axios
          .get(this.url)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getLivesData();
            }
            return e;
          });

        if (games) {
          for (let index = 0; index < games.length; index++) {
            if (games[index].tournament_name == 'FIFA World Cup') {
              this.nameFIFACup = games[index].tournament_name;
              for (let indexFifaCup = 0; indexFifaCup < games[index].games.length; indexFifaCup++) {
                this.gamesFIFACup.push({
                  LogoHomeTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexFifaCup].home_team + '.png',
                  LogoAwayTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' +
                    games[index].games[indexFifaCup].visiting_team +
                    '.png',
                  Date: games[index].games[indexFifaCup].schedule.substring(0, 5),
                  GameID: games[index].games[indexFifaCup].game_id,
                  seasonID: games[index].season_id,
                });
              }
            }
            if (games[index]._id === 1046) {
              this.nameLeaguesCup = games[index].tournament_name;
              for (let indexLeaguesCup = 0; indexLeaguesCup < games[index].games.length; indexLeaguesCup++) {
                this.gamesLeaguesCup.push({
                  LogoHomeTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexLeaguesCup].home_team + '.png',
                  LogoAwayTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' +
                    games[index].games[indexLeaguesCup].visiting_team +
                    '.png',
                  Date: games[index].games[indexLeaguesCup].schedule.substring(0, 5),
                  GameID: games[index].games[indexLeaguesCup].game_id,
                  seasonID: games[index].season_id,
                });
              }
            }
            if (games[index].tournament_name == 'Liga MX') {
              this.nameLigaMX = games[index].tournament_name;
              for (let indexGLMX = 0; indexGLMX < games[index].games.length; indexGLMX++) {
                this.gamesLigaMX.push({
                  LogoHomeTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexGLMX].home_team + '.png',
                  LogoAwayTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexGLMX].visiting_team + '.png',
                  Date: games[index].games[indexGLMX].schedule.substring(0, 5),
                  GameID: games[index].games[indexGLMX].game_id,
                  seasonID: games[index].season_id,
                });
              }
            }
            if (games[index].tournament_name == 'Liga MX Femenil') {
              this.nameLigaFMX = games[index].tournament_name;
              for (let indexGLFMX = 0; indexGLFMX < games[index].games.length; indexGLFMX++) {
                this.gamesFemenilMX.push({
                  LogoHomeTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexGLFMX].home_team + '.png',
                  LogoAwayTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexGLFMX].visiting_team + '.png',
                  Date: games[index].games[indexGLFMX].schedule.substring(0, 5),
                  GameID: games[index].games[indexGLFMX].game_id,
                  seasonID: games[index].season_id,
                });
              }
            }
            if (games[index].tournament_name == 'Liga Expansion MX') {
              this.nameLigaEMX = games[index].tournament_name;
              for (let indexGLEMX = 0; indexGLEMX < games[index].games.length; indexGLEMX++) {
                this.gamesExpansion.push({
                  LogoHomeTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexGLEMX].home_team + '.png',
                  LogoAwayTeam:
                    'https://az755631.vo.msecnd.net/teams-80/' + games[index].games[indexGLEMX].visiting_team + '.png',
                  Date: games[index].games[indexGLEMX].schedule.substring(0, 5),
                  GameID: games[index].games[indexGLEMX].game_id,
                  seasonID: games[index].season_id,
                });
              }
            }
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>
<style>
.lives-games-league .carousel .carousel-indicator .indicator-item .indicator-style {
  width: 17px;
  height: 5px;
  border-radius: 2px;
  background-color: #d8d8d8;
  border: none;
}
.lives-games-league .carousel .carousel-indicator .indicator-item.is-active .indicator-style {
  background: #7ed321;
}
.lives-games-league .carousel .carousel-indicator {
  top: 10px !important;
  margin-top: 10px !important;
}
</style>
<style lang="scss" scoped>
.fotter-space {
  width: 100%;
  height: 50px;
  // background: red;
  float: left;
}
.pc-games-flag {
  display: inline;
  @media screen and (max-width: 553px) {
    display: none;
  }
}
.movil-games-flag {
  display: none;
  @media screen and (max-width: 553px) {
    display: inline-block;
  }
}
.button-livedata {
  width: 169px;
  height: 28px;
  border-radius: 8px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(to bottom, #fecf23, #f1b211);
  background-image: linear-gradient(to bottom, #fecf23, #f1b211);
  font-family: 'Avenir-Demi';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.27px;
  text-align: center;
  color: #3b3b3b;
  margin: 0 auto;
}
.button-livedata:hover {
  background-image: linear-gradient(to bottom, #e2bd35, #d6a731);
  cursor: pointer;
}
.live-gameitem {
  width: 238px;
  height: 114px;
  margin-left: 30px;
  margin-top: 12px;
  float: left;
  border-radius: 4px;
  border: solid 1px #c2cfd9;
  background-color: #fff;
  &__date {
    font-size: 0.7rem;
    font-weight: 600;
    font-family: 'Avenir-Pro-Medium';
    margin-top: 2px;
    float: left;
    height: 20px;
    line-height: 22px;
    width: 100%;
    // background: magenta;
    text-align: center;
    color: #132839;
  }
  &__data {
    height: 56px;
    width: 238px;
    // background: gold;
    float: left;
    &__logo-home {
      width: 46px;
      height: 46px;
      float: left;
      margin-left: 38px;
      // background: cyan;
      margin-top: 4px;
      @media screen and (max-width: 553px) {
        margin-left: 70px;
      }
      @media screen and (max-width: 363px) {
        margin-left: 60px;
      }
    }
    &__logo-away {
      width: 46px;
      float: left;
      height: 46px;
      // background: cyan;
      margin-top: 4px;
    }
    &__vs {
      width: 67px;
      height: 46px;
      float: left;
      font-size: 1rem;
      font-family: 'Avenir-Pro-Medium';
      line-height: 46px;
      // background: rgba(7, 105, 105, 0.459);
      margin-top: 4px;
      color: #132839;
    }
    @media screen and (max-width: 553px) {
      width: 300px;
    }
    @media screen and (max-width: 363px) {
      width: 280px;
    }
  }
  &__container-button {
    width: 100%;
    height: 28px;
    float: left;
  }
  @media screen and (max-width: 853px) {
    margin-left: 5px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 43px;
  }
  @media screen and (max-width: 653px) {
    margin-left: 12px;
  }
  @media screen and (max-width: 553px) {
    width: 300px;
    margin: 0 auto;
  }
  @media screen and (max-width: 363px) {
    width: 280px;
  }
  // @media screen and (max-width: 453px) {
  //   width: 353px;
  // }
  // @media screen and (max-width: 363px) {
  //   width: 100%;
  // }
}
.lives-games-league {
  width: 100%;
  height: auto;
  display: inline-block;
  &__title {
    width: 100%;
    height: 33px;
    font-family: 'Avenir-Demi';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: -0.13px;
    text-align: center;
    color: #132839;
    @media screen and (max-width: 553px) {
      font-size: 20px;
      // background: red;
    }
    @media screen and (max-width: 353px) {
      font-size: 16px;
    }
  }
  &__containerleague {
    display: inline-block;
    width: 836px;
    min-height: 166px;
    // background: red;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 20px;
    position: relative;
    @media screen and (max-width: 853px) {
      width: 734px;
    }
    @media screen and (max-width: 768px) {
      width: 610px;
    }
    @media screen and (max-width: 653px) {
      width: 513px;
    }
    @media screen and (max-width: 553px) {
      width: 423px;
      margin-bottom: 2px;
    }
    @media screen and (max-width: 453px) {
      width: 353px;
    }
    @media screen and (max-width: 363px) {
      width: 100%;
    }
    &__titleleague {
      width: 836px;
      text-align: left;
      height: 30px;
      padding-left: 40px;
      float: left;
      // background: blue;
      font-family: 'Avenir-Pro-Bold';
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.12px;
      color: #132839;
      @media screen and (max-width: 853px) {
        width: 734px;
      }
      @media screen and (max-width: 768px) {
        width: 610px;
        padding-left: 55px;
      }
      @media screen and (max-width: 653px) {
        width: 513px;
        padding-left: 25px;
      }
      @media screen and (max-width: 553px) {
        width: 423px;
        text-align: center;
        padding-left: 0px;
      }
      @media screen and (max-width: 453px) {
        width: 353px;
      }
      @media screen and (max-width: 363px) {
        width: 100%;
      }
    }
    &__containergames {
      width: 836px;
      float: left;
      min-height: 114px;
      // background: greenyellow;
      @media screen and (max-width: 853px) {
        width: 734px;
      }
      @media screen and (max-width: 768px) {
        width: 610px;
      }
      @media screen and (max-width: 653px) {
        width: 513px;
      }
      @media screen and (max-width: 553px) {
        width: 423px;
        margin-top: 11px;
      }
      @media screen and (max-width: 453px) {
        width: 353px;
      }
      @media screen and (max-width: 363px) {
        width: 100%;
      }
    }
  }
}
</style>
