<template>
  <div class="lives-modal-container">
    <div class="columns is-gapless">
      <div class="column header-page">
        <span>Partidos en vivo</span><img class="icon-live" src="/images/menu/icn-live@2x.png" />
        <div class="close-button" @click="clickClose">
          <img src="/images/btn-close@2x.png" alt="close" width="48" />
        </div>
      </div>
    </div>
    <div div class="subheader-page">Elige el partido:</div>
    <div class="columns is-gapless is-centered">
      <div class="column is-justify-content-start">
        <div class="container-component-lives">
          <GamesLeague @game-select="clickClose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GamesLeague from '@/components/Sections/Lives/GamesLeague';
import { mapActions, mapMutations } from 'vuex';
export default {
  name: 'ContentModal',
  components: { GamesLeague },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataUpdate: 0,
      showLeague: true,
      showTeams: false,
      showPlayers: false,
      type: 1, //1 player, 0 team
    };
  },
  computed: {},
  async created() {
    var myDiv = document.getElementsByClassName('container-component-lives');
    myDiv.scrollTop = 0;
  },
  watch: {
    show() {
      if (!this.show) {
        this.showPlayers = false;
        this.showLeague = true;
        this.showTeams = false;
        this.dataUpdate += 1;
      }
    },
  },
  methods: {
    ...mapActions('mediaGeneral', ['getPlayersByTeam']),
    ...mapMutations('mediaGeneral', ['resetPlayer']),
    clickClose() {
      this.showPlayers = false;
      this.showLeague = true;
      this.showTeams = false;
      this.$emit('close');
      this.dataUpdate += 1;
    },
  },
};
</script>

<style scoped lang="scss">
.subheader-page {
  width: 100%;
  height: 33px;
  font-family: 'Avenir-Demi';
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: -0.13px;
  text-align: center;
  color: #132839;
  @media screen and (max-width: 553px) {
    margin-top: -40px;
    font-size: 20px;
  }
}
.lives-modal-container {
  background: white;
  min-height: 812px;
  border-radius: 19px;
  border-top-left-radius: 24px;
  border-top-right-radius: 0;
  overflow-x: hidden;
  @media screen and (max-width: 1553px) {
    width: 1053px;
    margin: 0 auto;
  }
  @media screen and (max-width: 1053px) {
    width: 953px;
    margin: 0 auto;
  }
  @media screen and (max-width: 953px) {
    width: 853px;
    margin: 0 auto;
  }
  @media screen and (max-width: 853px) {
    width: 768px;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    width: 653px;
    margin: 0 auto;
  }
  @media screen and (max-width: 653px) {
    width: 553px;
    margin: 0 auto;
  }
  @media screen and (max-width: 553px) {
    width: 100%;
    margin: 0 auto;
    border-radius: 0px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}
.speech-invesment {
  font-family: Avenir-Regular;
  max-width: 550px;
  margin: 0 15px;
  color: #132839;
  span:first-child {
    font-family: Avenir-Demi;
  }
}
.text-close-button {
  display: inline-block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.close-button {
  display: flex;
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  right: 0;
  top: 0;

  @media screen and (max-width: 768px) {
    border-radius: 33px;
    width: 32px;
    height: 32px;
    padding: 0 0;
    font-size: 28px;
    text-align: center;
    justify-content: center;
  }
  @media screen and (max-width: 553px) {
    width: 42px;
    height: 42px;
  }
}
.video {
  width: 100%;
  max-width: 700px;
}
.icon-live {
  width: 56px;
  height: 56px;
  margin: 55px 0 0 15px;
  @media screen and (max-width: 553px) {
    margin: 26px 0 0 8px !important;
    width: 48px;
    height: 48px;
  }
  @media screen and (max-width: 453px) {
    margin: 20px 0 0 8px !important;
    width: 48px;
    height: 48px;
  }
  @media screen and (max-width: 353px) {
    height: 48px;
    margin: 14px 0 0 8px !important;
  }
}
.header-page {
  height: 110px;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  justify-content: center;
  position: relative;
  span {
    height: 32px;
    font-family: 'Avenir-Demi';
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 50px;
    line-height: 0.84;
    letter-spacing: -0.8px;
    text-align: center;
    color: #132839;
    @media screen and (max-width: 553px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 453px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 353px) {
      font-size: 28px !important;
    }
  }
}
.columns {
  width: 100%;
  .column {
    padding: 40px 50px;
  }
}
.button {
  font-family: Avenir-Pro-Bold;
  padding: 15px 40px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 3.07;
  letter-spacing: -0.08px;
  text-align: center;
  border: none;
  color: #494a4b;
}
.button-links {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}
.is-selected {
  background-color: #efefef;
}
.is-justify-content-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}
@media screen and (min-width: 769px) {
  .is-justify-content-start {
    width: 40%;
  }
}
.mt-40 {
  margin-top: 40px !important;
}
.outline-right {
  position: relative;
}
.border-right {
  width: 2px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: #cdcdcd;
}
@media screen and (max-width: 880px) {
  .button {
    padding: 5px 15px;
  }
  section {
    position: relative;
  }
  @media screen and (max-width: 450px) {
    .header-page span {
      font-size: 22px;
    }
    .close-button {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2;
    }
  }
}
@media screen and (max-width: 768px) {
  .mt-40 {
    margin-top: 0 !important;
  }
  .columns {
    width: 100%;
    .column {
      padding: 0;
    }
  }
  .button-links {
    padding: 0 0 10px;
  }
  .button {
    padding: 5px 10px;
  }
  section {
    position: relative;
  }
}
.container-component-lives::-webkit-scrollbar {
  width: 9px; /* width of the entire scrollbar */
}

.container-component-lives::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

.container-component-lives::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* color of the scroll thumb */
  border-radius: 30px; /* roundness of the scroll thumb */
  border: 1px solid #a3a3a3; /* creates padding around scroll thumb */
}
.container-component-lives {
  width: 1053px;
  height: 680px;
  margin: 0 auto;
  overflow-y: auto !important;
  overflow-x: hidden !important;

  @media screen and (max-height: 820px) {
    height: 640px;
  }
  @media screen and (max-height: 780px) {
    height: 600px;
  }
  @media screen and (max-height: 700px) {
    height: 540px;
  }
  @media screen and (max-height: 650px) {
    height: 480px;
  }
  @media screen and (max-height: 610px) {
    height: 430px;
  }
  @media screen and (max-height: 580px) {
    height: 400px;
  }
  @media screen and (max-height: 550px) {
    height: 370px;
  }
  @media screen and (max-height: 520px) {
    height: 340px;
  }
  @media screen and (max-height: 490px) {
    height: 310px;
  }
  @media screen and (max-height: 460px) {
    height: 280px;
  }
  @media screen and (max-height: 430px) {
    height: 280px;
  }
  @media screen and (max-height: 400px) {
    height: 250px;
  }
  @media screen and (max-height: 370px) {
    height: 220px;
  }
  @media screen and (max-height: 340px) {
    height: 190px;
  }
  @media screen and (max-width: 1053px) {
    width: 953px;
  }
  @media screen and (max-width: 953px) {
    width: 853px;
  }
  @media screen and (max-width: 853px) {
    width: 768px;
  }
  @media screen and (max-width: 768px) {
    width: 653px;
  }
  @media screen and (max-width: 653px) {
    width: 553px;
  }
  @media screen and (max-width: 553px) {
    width: 453px;
  }
  @media screen and (max-width: 453px) {
    width: 100%;
  }
  @media screen and (max-width: 353px) {
    width: 100%;
  }
}
</style>
